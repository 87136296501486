<template>
  <section v-if="important_message" class="important-message-bar" ref="importnant-message-bar">
    <p data-cy="important-message">{{ important_message }}</p>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { UPDATE_IMPORTANT_MESSAGE_BAR_HEIGHT } from "../../stores/Styles/constants"
import { CLIENT_STORE } from "../../constants/other"
import { CONTENT_STORE } from "../../../Admin/constants/others_constants"

export default {
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "important_message_bar"
    ]),
    ...mapState(CONTENT_STORE, {
      important_message_bar_admin: "important_message_bar"
    }),
    important_message() {
      return this.admin_imported ?
        this.important_message_bar_admin : this.important_message_bar
    }
  },
  mounted() {
    if (this.important_message) this.update_height(this.$refs["importnant-message-bar"].clientHeight)
  },
  methods: {
    ...mapMutations("Styles", {
      update_height: UPDATE_IMPORTANT_MESSAGE_BAR_HEIGHT
    })
  }
}
</script>

<style lang="scss" scoped>
@use "../../../../styles/_global-constants" as *;

.important-message-bar {
  padding: 10px;
  font-weight: normal;
  color: $pure-white;
  background-color: $red-color;
  text-align: center;
  z-index: 1;

  p {
    margin: 0;
    font-size: 11px;
    box-sizing: border-box;

    &:first-child {
      margin: 0;
      font-size: 16px;
    }

    &:nth-child(2) {
      margin: 5px 0 0 ;
    }
  }
}
</style>
